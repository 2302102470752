import { getDefaultExtent } from '@/api/layer'

const MIN_Z_INDEX = 2
const state = {
  layerIds: [],
  defaultExtent: null,
  modules: [],
  labels: [],
}

const mutations = {
  SET_LABELS: (state, labels) => {
    state.labels = labels
  },

  REGISTER_MODULES: (state, id) => {
    const maxZIndex = state.modules.length > 0 ? Math.max(...state.modules.map(m => m.zIndex)) : MIN_Z_INDEX - 1
    state.modules.push({
      id,
      zIndex: maxZIndex + 1,
    })
  },

  DEREGISTER_MODULES: (state, id) => {
    const idx = state.modules.findIndex(m => m.id === id)
    state.modules.splice(idx, 1)
    for (let i = 0; i < state.modules.length; i++) {
      state.modules[i].zIndex = MIN_Z_INDEX + i //reset zIndex
    }
  },

  ADD_LAYER: (state, id) => {
    state.layerIds.push(id)
  },

  REMOVE_LAYER: (state, id) => {
    const idx = state.layerIds.indexOf(id)
    state.layerIds.splice(idx, 1)
  },

  SET_DEFAULT_EXTENT: (state, extent) => {
    state.defaultExtent = extent
  },
}

const actions = {
  async getDefaultExtent({ commit }) {
    try {
      const { data } = await getDefaultExtent()
      commit('SET_DEFAULT_EXTENT', data)
    } catch (error) {
      commit('SET_DEFAULT_EXTENT', null)
    }
  },
}

export default {
  namespaced: true,
  state,
  mutations,
  actions,
}
